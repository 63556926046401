import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  constructor(private router: Router) {

  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const apiListWithoutToken = ['useraccount/login',
       'customer/create', 'useraccount/check/username',
        'useraccount/check/phone', 'auth/useraccount/otp/password/reset',
         'auth/otp', 'forgot/username',
        'artist/profile/public', 'customer/job/id/get/public'];
    const token = localStorage.getItem('token') || sessionStorage.getItem('token');
    if (apiListWithoutToken.some(v => req.url.includes(v))) return this.errorHandling(req, next);
    if (token && token !== null) {
      let headers = {
        'Accept': 'application/json',
        'Authorization': token,
        'Content-Type': 'application/json',
      }
      if (req.headers.get('type') === 'file') delete headers['Content-Type'];
      req = req.clone({
        setHeaders: headers
      });
    } else {
      this.router.navigate(['/']);
    }
    return this.errorHandling(req, next);
  }

  errorHandling(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        let msg = err.error && err.error.message ? err.error.message : err.error;
        if (req.url.includes('useraccount/login') && err.status === 401) return throwError('Invalid credentials');
        if (err.status === 401 && !req.url.includes('useraccount/check/username')) {
          localStorage.clear();
          sessionStorage.clear();
          this.router.navigate(['/']);
          msg = 'Session expired. Please login again.';
        } else if (err.status === 403) {
          console.log(err);
        }
        if (err.error && err.error.message) {
          if (msg.includes('java.lang.RuntimeException:')) {
            msg = msg.split('java.lang.RuntimeException:')[1];
          }
          return throwError(msg);
        }
        return throwError(msg);
      })
    )
  }
}