import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { InstallAppModalComponent } from './common/install-app-modal/install-app-modal.component';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PwaService {
  private promptEvent: any;
  private modalRef;

  constructor(
    private platform: Platform,
    private modalService: NzModalService,
    private http: HttpClient,
  ) { }

  public initPwaPrompt() {
    if (this.platform.ANDROID) {
      window.addEventListener('beforeinstallprompt', (event: any) => {
        event.preventDefault();
        this.promptEvent = event;
      });
    }
    if (this.platform.IOS) {
      const isInStandaloneMode = ('standalone' in window.navigator) && (window.navigator['standalone']);
      if (!isInStandaloneMode) {
        this.promptEvent = true;
      }
    }
  }

  public showPrompt() {
    if (this.promptEvent) {
      if (this.platform.ANDROID) {
        this.openPromptComponent('android');
      }
      if (this.platform.IOS) {
        this.openPromptComponent('ios');
      }
    }
  }

  private openPromptComponent(mobileType: 'ios' | 'android') {
    if(!this.modalRef){
    this.modalRef =  this.modalService.create({
      nzTitle: $localize`Install ForScreens App`,
      nzContent: InstallAppModalComponent,
      nzFooter: null,
      nzData: { mobileType, promptEvent: this.promptEvent },
    });
  }
  }
}