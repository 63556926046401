import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './guards/auth.guard';
import { RoleGuardService } from './guards/role.guard';
import { SessionGuardService } from './guards/session.guard';
import { USER_ROLE } from './external/external.types';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import("./external/external.module").then(m => m.ExternalModule),
    canActivate: [SessionGuardService]
  },
  {
    path: 'user',
    loadChildren: () => import("./artist/artist.module").then(m => m.ArtistModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'moderator',
    loadChildren: () => import("./moderator/moderator.module").then(m => m.ModeratorModule),
    canActivate: [AuthGuardService, RoleGuardService],
    data: {
      roleGuard: { role: USER_ROLE.MODERATOR, redirectTo: '/' },
    },
  },
  {
    path: 'admin',
    loadChildren: () => import("./admin/admin.module").then(m => m.AdminModule),
    canActivate: [AuthGuardService, RoleGuardService],
    data: {
      roleGuard: { role: USER_ROLE.ADMIN, redirectTo: '/' },
    },
  },
  {
    path: 'customer',
    loadChildren: () => import("./customer/customer.module").then(m => m.CustomerModule),
    canActivate: [AuthGuardService, RoleGuardService],
    data: {
      roleGuard: { role: USER_ROLE.CUSTOMER_ADMIN, redirectTo: '/' },
    },
  },
  {
    path: 'portfolio',
    loadChildren: () => import("./common/profile-view/profile-view.module").then(m => m.ProfileViewModule),
  },
  {
    path: 'job/:id',
    loadChildren: () => import("./common/public-job-view/public-job-view.module").then(m => m.PublicJobViewModule),
  },
  { path: '404', loadChildren: () => import("./not-found/not-found.module").then(m => m.NotFoundModule)},
  { path: '**', redirectTo: '404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    scrollPositionRestoration: 'enabled',
  })],
  exports: [RouterModule],
  providers: [AuthGuardService, RoleGuardService, SessionGuardService]
})
export class AppRoutingModule { }
export const RoutingComponents = [];
