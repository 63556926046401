import { Directive, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: 'img',
})
export class PreventImageContextMenuDirective {
  @HostListener('contextmenu', ['$event'])
  onRightClick(event: Event): void {
    event.preventDefault();
  }
}