import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AppService } from '../app.service';

@Injectable()
export class SessionGuardService implements CanActivate {

  constructor(public router: Router, private appService: AppService) { }

  canActivate(): boolean {
    if (this.appService.isLoggedIn()) {
      if (this.appService.isModerator()) this.router.navigate(['moderator']);
      else if(this.appService.isCustomer()) this.router.navigate(['customer']);
      else this.router.navigate(['user']);
      return false;
    }
    return true;
  }

}