export type CUSTOMER_TYPE = 'SYSTEM' | 'CHANNEL' | 'PRODUCTION_HOUSE' | 'CASTING_AGENCY' | 'ARTIST' | 'OTHER';

export const PUBLIC_CUSTOMER_TYPE_LIST = [
  { key: 'CHANNEL', label: 'Channel' },
  { key: 'PRODUCTION_HOUSE', label: 'Production House' },
  { key: 'CASTING_AGENCY', label: 'Casting Agency' },
  { key: 'OTHER', label: 'Other' },
]

export type CUSTOMER_STATUS = 'PAID' | 'UNPAID';

export type ROLES = 'CUSTOMER_ADMIN' | 'CUSTOMER_USER' | 'MODERATOR' | 'SYSTEM_ADMIN' | 'USER';

export enum USER_ROLE {
  ADMIN = 'ROLE_ADMIN',
  USER = 'ROLE_USER',
  MODERATOR = 'ROLE_MODERATOR',
  CUSTOMER_ADMIN = 'ROLE_CUSTOMER_ADMIN',
  SYSTEM_ADMIN = 'SYSTEM_ADMIN',
}