import { Subject } from "rxjs";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { USER_ROLE } from "./external/external.types";
import { isPlatformBrowser, isPlatformServer } from "@angular/common";

const TOKEN = 'token';
const EXPIRATION = 'expiration';
const USER = 'user';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private _user;
  private avatarUrl = new Subject<string>();
  public avatarUrl$ = this.avatarUrl.asObservable();

  get customerId(): any {
    return this.getUserData()?.customerId;
  }

  get userId(): any {
    return this.getUserData()?.userId;
  }

  get username(): any {
    return this.getUserData()?.username;
  }

  get phoneNo(): any {
    return this.getUserData()?.mobileNumber;
  }

  constructor(private httpClient: HttpClient, @Inject(PLATFORM_ID) private platformId: Object) { }

  public isModerator(): boolean { return this.getUserData()?.roles.includes(USER_ROLE.MODERATOR) };
  public isSystemAdmin(): boolean { return this.getUserData()?.roles.includes(USER_ROLE.SYSTEM_ADMIN) };
  public isArtist(): boolean { return this.getUserData()?.roles.includes(USER_ROLE.USER) };
  public isCustomer(): boolean { return this.getUserData()?.roles.includes(USER_ROLE.CUSTOMER_ADMIN) };

  getUserData() {
    if (this._user) return this._user;
    else if (localStorage.getItem(USER)) return JSON.parse(localStorage.getItem(USER));
    else if (sessionStorage.getItem(USER)) return JSON.parse(sessionStorage.getItem(USER));
    else return null;
  }

  updateAvatarUrl(url: string): void {
    let user = this.getUserData();
    user.avatarUrl = url;
    this.avatarUrl.next(url);
    if (localStorage.getItem(TOKEN)) localStorage.setItem(USER, JSON.stringify(user));
    else if (sessionStorage.getItem(TOKEN)) sessionStorage.setItem(USER, JSON.stringify(user));
    this._user = user;
  }

  setSession(token, expiration, user) {
    localStorage.setItem(TOKEN, token);
    localStorage.setItem(EXPIRATION, expiration.toString());
  }

  setTempSession(token, expiration, user) {
    sessionStorage.setItem(TOKEN, token);
    sessionStorage.setItem(EXPIRATION, expiration.toString());
  }

  setUserInSession(res) {
    const user: any = {
      roles: res.roles,
      customerId: res.customerDTO.id,
      userId: res.userAccountDTO.id,
      username: res.userAccountDTO.username,
      mobileNumber: res.userAccountDTO.mobileNumber,
      name: res.userAccountDTO.name,
      artistType: res.customerDTO.subType,
      companyEmail: res.customerDTO.companyEmail,
      status: res.customerDTO.status,
    }
    if (res.artistInfo) {
      user.avatarUrl = res.artistInfo.avatarUrl;
      user.profileStatus = res.artistInfo.profileStatus;
      user.status = res.artistInfo.status;
    }
    if (localStorage.getItem(TOKEN)) localStorage.setItem(USER, JSON.stringify(user));
    else if (sessionStorage.getItem(TOKEN)) sessionStorage.setItem(USER, JSON.stringify(user));
    this._user = user;
  }

  clearSession() {
    localStorage.removeItem(TOKEN)
    localStorage.removeItem(EXPIRATION);
    sessionStorage.removeItem(TOKEN)
    sessionStorage.removeItem(EXPIRATION);
    localStorage.removeItem(USER);
    sessionStorage.removeItem(USER);
  }

  isLoggedIn() {
    if (isPlatformBrowser(this.platformId)) {
      let tempSession = sessionStorage.getItem('token') !== null && Number(sessionStorage.getItem(EXPIRATION)) > Date.now();
      let session = localStorage.getItem('token') !== null && Number(localStorage.getItem(EXPIRATION)) > Date.now();
      return tempSession || session;
    }
    if (isPlatformServer(this.platformId)) {
      return true;
    }
  }

}