/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then(() => {
      // if ('serviceWorker' in navigator && environment.production) {
      //   navigator.serviceWorker.register('./ngsw-worker.js')
      // }
    })
    .catch((err) => console.error(err));
});