import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SwPush } from "@angular/service-worker";
import { AppService } from "src/app/app.service";
import { environment } from "src/environments/environment";

@Injectable()
export class NotificationService {
  readonly VAPID_PUBLIC_KEY = "BANdUVpgA7cdf3DqspTjdM4m27_G0CvxYYv488L91zDRRpzaEVSBhFix3GQNB5ENul7EHC3FHMQ-zkd3d1f_6t0";

  constructor(private swPush: SwPush, private http: HttpClient, private appService: AppService) {
    this.listenToNotifications();
  }

  getActiveSubscription() {
    return this.swPush.subscription;
  }

  subscribeToNotifications() {
    this.swPush.requestSubscription({
      serverPublicKey: this.VAPID_PUBLIC_KEY
    }).then((res) => {
      console.log("User subscribed to notifications.");
      this.sendSubscriptionToServer(res);
    })
      .catch(err => console.error("Could not subscribe to notifications", err));
  }

  private sendSubscriptionToServer(subscription: PushSubscription) {
    const key = subscription.getKey('p256dh');
    const base64Key = this.arrayBufferToBase64(key);
    const auth = subscription.getKey('auth');
    const authKey = this.arrayBufferToBase64(auth);
    const payload = {
      endpoint: subscription.endpoint,
      userPublicKey: base64Key,
      authKey: authKey,
      payload: JSON.stringify({
        userAccountId: this.appService.userId,
        customerId: this.appService.customerId,
      })
    };

    this.http.post(environment.apiUrl + '/client/notificationsubscription/subscribe', payload).subscribe({
      next: (response) => {
        console.log('Subscription stored on server:', response)
      },
      error: (err) => {
        console.error('Error storing subscription on server:', err)
      }
    })
  }

  private arrayBufferToBase64(buffer: ArrayBuffer): string {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  private listenToNotifications():void {
    this.swPush.notificationClicks.subscribe(({action, notification}) => {
      console.log(action, notification);
      switch(action) {
        case 'open':
          // window.open(notification.data.url);
          break;
        default:
          console.log('notification clicked')
          break;
      }
      // TODO: define actions and redirect to appropriate page
    })
  }

}


// Notification format:
// {"notification":{"title":"Test push message from DevTools"}}