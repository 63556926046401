<div>
  <div *ngIf="nzModalData.mobileType === 'android'" class="text-center">
    <span class="text-lg" i18n>To get instant Casting updates, Add ForScreens app to your HomeScreen.</span>
    <nz-divider></nz-divider>
    <div class="flex justify-center items-center">
      <button type="button" nz-button nzType="default" nzShape="round" (click)="destroyModal()">
        <span i18n>Close</span>
      </button>
      <button class="ml-2" type="button" nz-button nzType="primary" nzShape="round" (click)="installPwa()">
        <span i18n>Add to Home screen</span>
      </button>
    </div>
  </div>
  <div *ngIf="nzModalData.mobileType === 'ios'">
    <div class="text-center">
      <span class="text-lg">To get instant Casting updates, Add ForScreens app to your HomeScreen. To install this web
        app on your device tap the Menu button and then 'Add to Home
        screen' button</span>
      <div class="flex justify-center items-center mt-4 gap-2">
        <img src="../../../assets/images/ios-share.svg" alt="ios-share" class="w-6 h-6">
        <span nz-icon nzType="arrow-right" nzTheme="outline"></span>
        <div class="rounded border border-black p-1 h-6 w-6 flex justify-center">
          <span nz-icon nzType="plus" nzTheme="outline"></span>
        </div>
      </div>
    </div>
    <nz-divider></nz-divider>
    <div class="flex justify-center items-center">
      <button type="button" nz-button nzType="primary" nzShape="round" (click)="destroyModal()">
        <span i18n>Close</span>
      </button>
    </div>
  </div>
</div>