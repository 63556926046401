import { Component, inject } from "@angular/core";
import { NZ_MODAL_DATA, NzModalRef } from "ng-zorro-antd/modal";

@Component({
  selector: 'app-install-app-modal',
  templateUrl: './install-app-modal.component.html'
})
export class InstallAppModalComponent {
  readonly nzModalData: { mobileType: 'ios' | 'android', promptEvent?: any } = inject(NZ_MODAL_DATA);

  constructor(
    private modal: NzModalRef,
  ) { }

  public installPwa(): void {
    this.nzModalData.promptEvent.prompt();
    this.destroyModal();
  }

  destroyModal(): void {
    this.modal.destroy();
  }
}